body.blog .pre-footer, body.blogpost .pre-footer {
  padding-top: 60px;
  margin-top: 0;
}

#blogposts {
  padding-top: 3rem;
  padding-bottom: 5rem;
}

#blogposts article {
  margin-top: 1rem;
  display: flex;
}

#blogposts article .blogpost-img {
  flex: 1;
}

#blogposts article .blogpost-img img {
  width: 100%;
}

@media screen and (max-width: 800px) {
  #blogposts article .blogpost-img img {
        min-width: 130px;
    margin-top: 2rem;
  }
}

#blogposts article .blogpost-text {
  flex: 4;
  margin-left: 50px;
}

#blogposts article .blogpost-text h2 {
  padding-bottom: 0;
  padding-top: 1.5rem;
}

#blogposts article .blogpost-text h2 a {
  text-decoration: none;
  color: #143942;
}

#blogposts article .blogpost-text time, #blogpost time {
  font-weight: 700;
  color: #4F828E;
  text-transform: uppercase;
  padding-bottom: 1rem;
}

#blogposts article .blogpost-text p {
  
}



#blogpost img {
  grid-column: full;
  width: 100%;
}

#blogpost header {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#blogpost h1 {
  color: #143942;
}

#blogpost {
  padding-bottom: 5rem;
}