@import './reset.css';
@import './modules.css';
@import './home.css';
@import './gardens.css';
@import './garden.css';
@import './whatwedo.css';
@import './team.css';
@import './blog.css';
@import './contact.css';

html,body
{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong { font-weight: bold; }

body {

  font-family: 'Open Sans', sans-serif;
  color: var(--body-text-color);
  display: grid;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  line-height: 1.5rem;
  grid-template-columns:
    [full-start] minmax(1rem, 1fr)
    [main-start] minmax(0, 1100px)
    [main-end] minmax(1rem, 1fr) [full-end];
}

h1 {
  font-family: 'Sanchez', Georgia, serif;
  color: var(--primary-color);
  font-size: 2rem;
  line-height: 2.5rem;
  padding-bottom: 1rem;
}

h2 {
  font-family: 'Sanchez', Georgia, serif;
  color: var(--primary-color);
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 1rem;
}

a {
  color: var(--interaction-color);

  &:hover {
    color: var(--interaction-hover-color);
  }
}

p {
  padding: 0.4rem 0;
}

.full-width {
  grid-column: full;
}

.grid {
  display: grid;
  grid-template-columns:
    [full-start] minmax(1rem, 1fr)
    [main-start] minmax(0, 1100px)
    [main-end] minmax(1rem, 1fr) [full-end];
}

.grid {
  display: grid;
  grid-template-columns:
    [full-start] minmax(1rem, 1fr)
    [main-start] minmax(0, 1100px)
    [main-end] minmax(1rem, 1fr) [full-end];
}

.grid-small {
  display: grid;
  grid-template-columns:
    [full-start] minmax(1rem, 1fr)
    [main-start] minmax(0, 900px)
    [main-end] minmax(1rem, 1fr) [full-end];
}

.page-width {
  grid-column: main;
}

body > * {
  grid-column: main;
}

/* Header */

header > nav {
  display: flex;
  justify-content: flex-end;

  & > ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & > li > a {
      display: inline-block;
      padding: 1.5rem 1rem;
      color: var(--body-text-color);
      font-size: 0.9rem;
      font-weight: 600;
      text-decoration: none;
    }
  }
}

/* Footer */

#page-footer {
  background: var(--dark-bg-color);
  color: white;
  padding: 2rem 0 4rem 0;
  margin-top: -1px;

  & address > h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
  }
}

#footer-grid {
  grid-column: main;
  display: grid;

  grid-template-columns: 30% 70%;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'sitemap contact'
    'social social'
    'copyright copyright'
}

#footer-site-map {
  grid-area: sitemap;
  line-height: 1.6rem;
}

#footer-contact-info {
  grid-area: contact;
  text-align: right;
}

#footer-social-links {
  grid-area: social;
  text-align: center;

  & > a {
    color: white;
  }
}

#footer-copyright {
  grid-area: copyright;
  text-align: center;
  padding-top: 2rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
}

.pre-footer {
  background: var(--dark-bg-color);
  color: white;
  text-align: center;
  padding-top: 260px;
  margin-top: -160px;
  z-index: 0;
  position: relative;
}


/* Feature Boxes */

.cards {
  display: flex;
  justify-content: center;

  & > figure {
    display: flex;
    min-width: 218px;
    flex-direction: column;
    align-items: center;
    width: 33%;
    padding: 0 1rem;
    text-align: center;

    & > .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 71px;
      height: 71px;
      color: white;
      background: var(--primary-color: #4F828E);
      border-radius: 50%;
    }

    & > figcaption {
      font-size: 0.9rem;

      & > h3 {
        font-weight: bold;
        padding-top: 1rem;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .cards {
    flex-wrap: wrap;
  }

  .cards > figure {
    margin-top: 2rem !important;
  }
}

.cards.enclosed > figure {
  justify-content:;
  padding: 2rem 1rem;
  box-shadow: 0 0 20px rgba(0,0,0, 0.1);
  margin: 0 1rem;
  background: white;

  & > figcaption {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    & > .button { margin-top: auto }
  }
}

span.fa-fallback {
  display: none;
}

/* Buttons */

.button-full-width {
  display: block;
  width: 100%;
}

.button {
  display: inline-block;
  background: var(--interaction-color);
  padding: 1rem;
  margin-top: 1.5rem;
  position: relative;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  bottom: 0;
  transition: all 0.2s;

  &:hover {
    background: var(--interaction-hover-color);
    color: white;
    bottom: 2px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
  }

  & > .button-text {
    margin: 0 10px;
  }
}

.button.with-right-arrow > .button-text:after {
  content: '>';
  display: block;
  transform: scale(1, 2);
  float: right;
  font-weight: 100;
  color: rgba(255,255,255,0.5);
}

.button.with-left-arrow > .button-text:before {
  content: '<';
  display: block;
  transform: scale(1, 2);
  float: left;
  font-weight: 100;
  color: rgba(255,255,255,0.5);
}