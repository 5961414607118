body.whatwedo #garden-intro-body {
  padding: 6rem 0;
  max-width: 500px;
}

body.whatwedo .pre-footer {
  padding-top: 60px;
  margin-top: 0;
}

.whatwedo-intro-page-container {
  grid-column: main;
  position: relative;
}

.whatwedo-intro-body {
  padding: 0 5rem;
}

#whatwedo-down-arrow {
  width: 230px;
  height: 230px;
  position: absolute;
  background: #61E1D5;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: white;
  right: 60px;
  bottom: -100px;
  z-index: 0;
}

.whatwedo-service {
  z-index: 1;
}

.whatwedo-service > div {
  padding: 3em 0;
  display: flex;
  align-items: center;
}

.whatwedo-service:nth-child(odd) {
  background-color: #F6F7F9;
}

.whatwedo-service:nth-child(odd) .whatwedo-service-body {
  order:2;
}

.whatwedo-service:nth-child(odd) .whatwedo-service-images {
  order:1;
  padding: 0 7rem 0 0;
  margin-top: -80px;
}

.whatwedo-service h2 {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
}

.whatwedo-service-body {
  flex: 1;
}

.whatwedo-service-images {
  flex: 1;
  padding-left: 7rem;
  
  display: flex;
  flex-direction: column;
}

.whatwedo-service-images div {
  max-width: 70%;
}

.whatwedo-service-images div+div {
  margin-left: 140px;
  margin-top: -120px;
}

.whatwedo-service-images img {
  width: 100%;
  box-shadow: 0 0 20px rgb(0,0,0,0.4);
}

@media screen and (max-width: 900px) {
  #whatwedo-down-arrow { display: none; }
  .whatwedo-service-images { padding-top: 3rem; }
  .whatwedo-service > div { display: block; }
  .whatwedo-service:nth-child(odd) .whatwedo-service-images { margin-top: 50px }
}

@media screen and (max-width: 550px) {
  .whatwedo-service-images {
    padding-left: 0;
  }
  .whatwedo-service:nth-child(odd) .whatwedo-service-images {
    padding-right: 0;
  }
  .whatwedo-service-images div+div {
    margin: 0;
  }
  .whatwedo-service-images div {
    max-width: 100%;
  }
}