#gardens {
  position: relative;
  z-index: 1;
}

#gardens-ul a {
  font-family: 'Sanchez', Georgia, serif;
  width: 100%;
  display: block;
  text-align: center;
  padding: 10rem 0;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-decoration: none;
  color: white;
  box-shadow: 0 4px 30px rgba(0,0,0,.3);
  border-radius: 5px;
  background-size: cover;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
  transition: transform 0.7s;
}

#gardens-ul a:hover {
  transform: scale(1.02, 1.02);
}