#contact-heading h1 {
  text-align: center;
  padding: 6rem 0;
}

#contact-body {
  display: flex;
  padding-bottom: 4rem;
}


@media screen and (max-width: 800px) {
  #contact-body {
    display: block;
  }
}

#contact-details {
  flex: 1;
}

.alert {
  background: #61e1d5;
  color: white;
  text-align: center;
  padding: 0.4rem;
  font-style: italic;
}

#contact-details .contact-name {
  font-weight: 700;
}

#contact-details .contact-social-links {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#contact-details .contact-social-links a {
  font-size: 50px;
  color: var(--body-text-color);
}

#contact-details .icon:before {
  display: inline-block;
  margin-right: .5em;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

#contact-form {
  flex: 1;
}

#contact-form:after { /* clearfix */
  content: "";
  clear: both;
  display: table;
}

#contact-form label, #contact-form input, #contact-form textarea {
  display: block;
}

#contact-form label {
  text-transform: uppercase;
  color: var(--label-color);
  font-weight: bold;
  font-size: 1rem;
  line-height: 2.5rem;
}

#contact-form input.text-field {
  color: #333;
  background: #F9F9F9;
  width: 100%;
  font-size: 1.2rem;
  line-height: 2.5rem;
  padding-left: 10px;
  box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.25);
  border: 0;
  outline: none;
  outline-style:none;
}

#contact-form textarea {
  color: #333;
  background: #F9F9F9;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding-left: 10px;
  padding-top: 10px;
  box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.25);
  border: 0;
  outline: none;
  outline-style:none;
}

#contact-form input.button {
  cursor: pointer;
  min-width: 50%;
  float: right;
}

#contact-form textarea {
  width: 100%;
}
