body.team .pre-footer {
  padding-top: 60px;
  margin-top: 0;
}

body.team h1 {
  padding-top: 7rem;
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
}

body.team #quote-container {
  padding-top: 4rem;
  padding-bottom: 10rem;
  display: flex;
}

@media screen and (max-width: 700px) {
  body.team #quote-container {
    display: block;
  }
}

body.team #quote-container .image {
  flex: 1;
  padding-right: 3rem;
  padding-bottom: 2rem;
}

body.team #quote-container .image img {
  width: 100%;
  border-radius: 100%;
}

body.team #quote-container blockquote {
  flex: 2;
  padding-left: 50px;
}

body.team #quote-container blockquote::before {
  content: "“";
  float: left;
  font-size: 10rem;
  margin-left: -60px;
  line-height: 10rem;
  font-family: 'Sanchez', Georgia, serif;
}

body.team #quote-container blockquote footer {
  padding-top: 2rem;
  float: right;
}

body.team #quote-container blockquote footer::before {
  content: '—';
  float: left;
  margin-left: -2rem;
  line-height: 2.3rem;
}