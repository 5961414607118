body.garden {
  background-color: var(--dark-bg-color-alt);
  color: var(--dark-body-text-color);
  
  h1 {
    color: white;
  }
  
  & > header > nav > ul > li > a {
    color: white;
    
    &:hover {
      color: var(--interaction-hover-color);
    }
  }
}

body.garden .pre-footer {
  padding-top: 60px;
  margin-top: 0;
  border-top: 3px solid var(--interaction-hover-color);
}

#garden-intro { background-size: cover }

#garden-intro header > nav > ul > li > a {
  color: white;
  
  &:hover {
    color: var(--interaction-hover-color);
  }
}

#garden-intro-body {
  max-width: 600px;
  padding-bottom: 4rem;
}

#garden-intro p { color: #DEF5FC }

#garden-intro h1 {
  color: white;
  padding-top: 2rem;
}

#garden-gallery {
  width: 100%;
  padding: 2rem 0;
}

#garden-gallery > ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 750px) {
  #garden-gallery > ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 500px) {
  #garden-gallery > ul {
    grid-template-columns: 1fr;
  }
}

#garden-gallery ul img {
  width: 100%;
}

/* Lightbox */

#lightbox {
  display: none;
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;
  background: rgba(0,0,0,0.7);
  align-items: center;
  justify-content: center;
}

#lightbox-image {
  max-width: 80%;
  background: transparent url('/images/loading.gif') no-repeat scroll center center;
}

#lightbox::after {
  content:'✕';
  position: absolute;
  right: 1em;
  top: 1em;
  font-size: 2em;
  color: white;
}

#lightbox.active {
  display: flex;
}

.lightbox-link {
  display: block;
}