/* Intro Section */

#home-hero-image {
  position: relative;
  height: 100%;
  max-height: 350px;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: relative;
    z-index: 0;
  }
}

#home-logo {
  text-align: center;
  margin-top: -90px;
  position: relative;
  z-index: 1;
}

/* About Section */

#home-about h1 {
  text-align: center;
  padding-bottom: 0.3rem;
}

#home-about h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-family: 'Open Sans', sans-serif;
  color: var(--body-text-color);
  font-size: 19px;
  line-height: 1.3rem;
}

#home-about .cards p {
  padding: 0;
}

#home-about-photos {
  display: flex;
  padding-top: 4rem;

  & > div > img {
    max-width: 100%;
    box-shadow: 0 0 20px rgb(0,0,0,0.4);
    position: relative;
    z-index: 2;
  }

  & :nth-child(2) {
    position: relative;
    margin-left: -35px;
    margin-top: 39px;
  }

  & :nth-child(3) {
    margin-left: -35px;
    flex-shrink: 0.9;
    margin-top: 72px;
  }

  & :nth-child(4) {
    position: relative;
    margin-left: -35px;
  }
}

@media screen and (max-width: 500px) {
  #home-about-photos {
    flex-wrap: wrap;
  }

  #home-about-photos > div {
    margin: 0 !important;
    flex-shrink: 1 !important;
  }
}

#home-whatwedo {
  background-color: #F6F7F9;
  margin-top: -160px;
  padding-top: 230px;

  & h2 {
    text-align: center;
    padding-bottom: 3rem;
  }

  & .cards > figure { 
    position: relative;
    z-index: 1;
  }

  & .cards > figure:nth-child(2n) {
    position:relative;
    top: 40px;
  }

  & .cards.enclosed > figure > .icon {
    background: none;
    color: var(--primary-color: #4F828E);
  }
}

@media screen and (max-width: 900px) {
  .cards {
    flex-wrap: wrap;
  }

  #home-whatwedo .cards > figure:nth-child(2n) {
    top: 0px;
  }
}
